"use strict";

import Config from "./config";

const env = Config.env || "dev";
const apiRoot = Config[env].apiRoot;

const urlfy = (obj) =>
  Object.keys(obj)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
    .join("&");

class Api {
  async get(apiSrv, params) {
    let uriparams = params ? "?" + urlfy(params) : "";
    let response = null;
    const query = await fetch(
      `${apiRoot}${apiSrv +
        `?token=${process.env.REACT_APP_CODIGO_EMPRESAS_CMS}&tempo_token=${process.env.REACT_APP_CODIGO_TOKEN_TEMPO}`}`,
      {
        method: "GET",

        cache: "no-cache",

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Target-URL": "https://cmsgt.innappgt.com",
        },
        redirect: "follow",
        referrer: "no-referrer",
      }
    );

    // console.log(query, 'CONSOLE LOG DE QUERY ==================')
    if (query.status === 200) {
      response = await query.json();
      if (
        (response.Error && response.Error != null) ||
        (response.Resultado &&
          response.Resultado === "No se encontro ningún registro")
      ) {
        response = [];
      } else if (
        response.Message &&
        response.Message === "Authorization has been denied for this request."
      ) {
        console.log("Denegado");
      }
    } else {
      console.log("No se encontro");
    }

    return response;
  }
  async get2(apiSrv, params) {
    let uriparams = params ? "?" + urlfy(params) : "";
    let response = null;

    const query = await fetch(`${apiRoot}${apiSrv}${uriparams}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Target-URL": "https://cmsgt.innappgt.com",
      },
      redirect: "follow",
      referrer: "no-referrer",
    });
    //console.log(query, 'CONSOLE LOG DE QUERY ==================')
    if (query.status === 200) {
      response = await query.json();

      if (
        (response.Error && response.Error != null) ||
        (response.Resultado &&
          response.Resultado === "No se encontro ningún registro")
      ) {
        response = [];
      } else if (
        response.Message &&
        response.Message === "Authorization has been denied for this request."
      ) {
        console.log("Error: " + response.Message);
      }
    } else {
      console.log("error");
    }
    return response;
  }

  async post(apiSrv, body) {
    // let uriparams = params ? '?' + urlfy(params) : '';
    let response = null;
    const query = await fetch(
      `${apiRoot}${apiSrv +
        `token=${process.env.REACT_APP_CODIGO_EMPRESAS_CMS}&tempo_token=store`}`,
      {
        method: "POST",
        crossorigin: true,
        mode: "no-cors",
        cache: "no-cache",
        credentials: "same-origin",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Target-URL": "https://cmsgt.innappgt.com",
        },
      }
    );

    if (query.status === 200) {
      response = await query.json();

      if (
        (response.Error && response.Error != null) ||
        (response.Resultado &&
          response.Resultado === "No se encontro ningún registro")
      ) {
        response = [];
      } else if (
        response.Message &&
        response.Message === "Authorization has been denied for this request."
      ) {
        console.log("Denegado");
      }
    } else {
      console.log("error");
    }
    return response;
  }
}

export default new Api();

import { ENVIO_PAGO, LOGIN } from '../constants/action-types';
import { findIndex } from '../utils';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = { envio: {} }

const envio = ( state = { envio: {} }, action ) => {

    switch ( action.type ) {
        case ENVIO_PAGO:
            
                return {
                    ...state,
                    envio:action
                };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: "porto-",
    key: "wishlists",
    storage
}

export default persistReducer( persistConfig, envio );
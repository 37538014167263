import { LOGIN } from '../constants/action-types';
import { findIndex } from '../utils';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = { login: {} }

const loginReucer = ( state = { login: {} }, action ) => {

    switch ( action.type ) {
        case LOGIN:
            
                return {
                    ...state,
                    login:action
                };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: "porto-",
    key: "wishlists",
    storage
}

export default persistReducer( persistConfig, loginReucer );
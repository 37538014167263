import { combineReducers } from 'redux';
import cartReducer from './cart';
import productReducer from './product';
import wishReducer from './wishlist';
import demoReducer from './demo';
import compareReducer from './compare';
import filtersReducer from './filter';
import loginReucer from "./login"
import envio from './envio';
import metodoPago from './metodoPago';
import datos from './datos';
import banner from './banner';
import form from './form';
const rootReducer = combineReducers( {
    cartList: cartReducer,
    data: productReducer,
    wishlist: wishReducer,
    demo: demoReducer,
    compareList: compareReducer,
    filter: filtersReducer,
    login:loginReucer,
    envio:envio,
    metodo:metodoPago,
    datos:datos,
    banner:banner,
    form:form
} );


export default rootReducer;
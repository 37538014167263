import { FORM } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
const formjs = ( state = { data: {} }, action ) => {

    switch ( action.type ) {
        case FORM:
            
                return {
                    ...state,
                    data:action
                };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: "porto-",
    key: "form",
    storage
}

export default persistReducer( persistConfig, formjs );
import {
    FETCH_SINGLE_PRODUCT,
    RECEIVE_PRODUCTS,
    SHOW_QUICKVIEW,
    HIDE_QUICKVIEW,
    REFRESH_STORE,
    RECEIVE_CATEGORIES,
    RECEIVE_SHOPS,
    QUICK_VIEW_CONDITIONS,
    HIDE_QUICK_VIEW_CONDITIONS,
    HIDE_QUICK_VIEW_DENEGATE,
    QUICK_VIEW_DENEGATE,
    QUICK_VIEW_USER_VERIFICATION,
    HIDE_QUICK_VIEW_USER_VERIFICATION
} from '../constants/action-types'
import { findIndex } from '../utils';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    products: [],
    categories: [],
    shops: [],
    single: {},
    quickShow: false,
    conditionsShow: false,
    denegateShow: false,
    infoUserShow: false,
    fetchBegin: true,
}

const productReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case RECEIVE_PRODUCTS:
            return { ...state, products: action.products };
        case RECEIVE_CATEGORIES:
            return { ...state, categories: action.categories };
        case RECEIVE_SHOPS:
            return { ...state, shops: action.shops };
        case SHOW_QUICKVIEW:
            return { ...state, single: action.product, quickShow: true };
        case QUICK_VIEW_CONDITIONS:
            return { ... state, single: action.product, conditionsShow: true}
        case QUICK_VIEW_DENEGATE:
            return { ... state, single: action.product, denegateShow: true}
        case QUICK_VIEW_USER_VERIFICATION:
            return {... state, single: action.product, infoUserShow: true}
        case HIDE_QUICKVIEW:
            return { ...state, quickShow: false };
        case HIDE_QUICK_VIEW_CONDITIONS:
            return {...state, conditionsShow: false}
        case HIDE_QUICK_VIEW_DENEGATE: 
            return {...state, denegateShow: false}
        case HIDE_QUICK_VIEW_USER_VERIFICATION:
            return {...state, infoUserShow: false }

        case FETCH_SINGLE_PRODUCT:
            if ( findIndex( state.products, action.productId ) ) {
                const single = state.products.reduce( ( itemAcc, product ) => {
                    return product
                }, [] )
                return { ...state, single: single };
            }
            break;

        case REFRESH_STORE:
            return initialState;

        default:
            return state;
    }
};

const persistConfig = {
    keyPrefix: "porto-",
    key: "products",
    storage
}

export default persistReducer( persistConfig, productReducer );
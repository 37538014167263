import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "react-app-polyfill/ie11";
import firebase from "@firebase/app";
import "@firebase/messaging";
import "@firebase/analytics";
import "@firebase/auth";
import "@firebase/firestore";
import { connect } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { firebaseConfig, getConfig } from "./components/common/api";

// import store
import store, { persistor } from "./store";

// import action
import {
  getAllCart,
  getAllCategories,
  getAllProducts,
  getAllShops,
  refreshStore,
  showQuickView,
  showConditions,
  showDenegateBuy,
  addToCart,
  AddCartApi,
  AddCartApi3,
  login,
  logins,
} from "./action";

//import utils
import { definePolyfills, scrollTop } from "./utils";

// import routes
import Routes from "./routes";
import api from "./utils/api";
import config from "./utils/config";

//import {getConfig} from './components/common/api';

export function Root(props) {
  store.dispatch(getAllProducts());
  store.dispatch(getAllCategories());
  store.dispatch(getAllShops());
  const [dataTemp, setDataTemp] = useState();
  const [dataTemp2, setDataTemp2] = useState();

  const {
    addClass,
    product,
    showQuickView,
    addToCart,
    AddCartApi,
    showConditions,
    showDenegateBuy,
  } = props;

  // if(firebase.apps.length === 0) {
  //     firebase.initializeApp(firebaseConfig);
  //     if ('serviceWorker' in navigator) {
  //         navigator.serviceWorker.register('./firebase-messaging-sw.js')
  //         .then(function(registration) {
  //             firebase.messaging().useServiceWorker(registration);

  //         }).catch(function(err) {

  //         });
  //       }
  // }

  // {

  // }
  // const messaging = firebase.messaging();

  // messaging.getToken({ vapidKey: 'BPhpsGchSudE_Rh6S7wjq0fo2gXpfJ9pxxxNMIV3PSuIY9UBmhK4YUelfyXWhokqnMeunPJKaoM56A33rGILWLU' })
  //         .then((currentToken) => {
  //             if(currentToken) {

  //                 localStorage.removeItem('token_temp')
  //                 localStorage.setItem('token_temp', JSON.stringify(currentToken))
  //             } else {

  //             }
  //         }).catch((err) => {

  //         })

  definePolyfills();
  scrollTop();
  useEffect(() => {
    getConfig();

    if (store.getState().demo.current !== 15) {
      store.dispatch(refreshStore(15));
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<span></span>}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Routes />
          </ScrollContext>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
const mapStateToProps = (state, props) => {
  return {
    lista: state,
  };
};
connect(mapStateToProps, {
  AddCartApi,
  addToCart,
  showQuickView,
  showConditions,
  showDenegateBuy,
})(Root);

ReactDOM.render(<Root />, document.getElementById("root"));

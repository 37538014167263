// Get products
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const HIDE_QUICKVIEW = 'HIDE_QUICKVIEW';
export const DATOS_PERSONALES = 'DATOS_PERSONALES';
export const METODO_PAGO = 'METODO_PAGO';

export const BANNER = "BANNER"
//Get Categories
export const RECEIVE_CATEGORIES = 'RECEIVE_PRODUCT';

//Get Shops
export const RECEIVE_SHOPS = 'RECEIVE_SHOP';
export const LOGIN = 'LOGIN';

//Get Login Temp
export  const RECEIVE_TEMP = 'RECEIVE_TEMP';

// Filter Products
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const FILTER_CATEGORIES = 'FILTER_CATEGORIES';
export const FILTER_SIZE = 'FILTER_SIZE';
export const SORT_BY = 'SORT_BY';
export const FILTER_CLEAN_FILTERS = 'FILTER_CLEAN_FILTERS';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const CLEAR_CART = 'CLEAR_CART';
export const SEND_PRODUCT_CART = 'SEND_PRODUCT_CART';
export const RECEIVE_CART = 'RECEIVE_CART';
export const ENVIO_PAGO = 'ENVIO_PAGO';

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const REMOVE_ALL_COMPARE = 'REMOVE_ALL_COMPARE';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

//Demo change
export const HIDE_CART_MODAL = 'HIDE_CART_MODAL';
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL';

//Newsletter PopUp Modal
export const HIDE_NEWSLETTER = 'HIDE_NEWSLETTER';

//Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//get Form dynamic
export const FORM = 'FORM';

//Show modal for conditions veriff
export const QUICK_VIEW_CONDITIONS = "QUICK_VIEW_CONDITIONS"
export const HIDE_QUICK_VIEW_CONDITIONS = "HIDE_QUICK_VIEW_CONDITIONS" 

//Show modal for denegate buy on secure
export const QUICK_VIEW_DENEGATE = "QUICK_VIEW_DENEGATE"
export const HIDE_QUICK_VIEW_DENEGATE = "HIDE_QUICK_VIEW_DENEGATE" 

//Show modal for verification
export const QUICK_VIEW_USER_VERIFICATION = "QUICK_VIEW_USER_VERIFICATION"
export const HIDE_QUICK_VIEW_USER_VERIFICATION = "HIDE_QUICK_VIEW_USER_VERIFICATION" 
import config from "../../utils/config";
import api from "../../utils/api";

export const urlImage = {
  // ruta: 'https://6752cq1fwe.execute-api.us-east-1.amazonaws.com/dev/'
  ruta: process.env.REACT_APP_AMBIENTE_CMS,
  //ruta: 'https://cms.five-hn.com/'
};

export const code = {
  codigo: "+502",
};

export const coin = {
  moneda: "Q",
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_AMBIENTE_FIREBASE_API_KEY,
  authDomain: "e-commerce-d293d.firebaseapp.com",
  projectId: "e-commerce-d293d",
  storageBucket: "e-commerce-d293d.appspot.com",
  messagingSenderId: "609889668312",
  appId: "1:609889668312:web:28b73060b67c0582d4dbad",
  measurementId: "G-DJMG4422VZ",
};

export const getConfig = async () => {
  let response = await api.get2(config.apiSrvDatos, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
  });
  if (response.VALIDO === 1) {
    localStorage.removeItem("config");
    let newData = (({
      COLOR,
      NOMBRE,
      DETALLE,
      DIRECCION,
      CONTACTO,
      FILTRO_PRECIO,
      LOGO,
      MONTO_ENVIO_GRATIS,
      PAGA_DEPOSITO,
      PAGAPEDIDO,
      PAGA_VISA,
      PAGINA,
      TELEFONO,
      TEXTO_COMPRA,
      VALIDO,
      BANNER,
      MONEDA,
      TIPO_CAMBIO,
    }) => ({
      COLOR,
      NOMBRE,
      DETALLE,
      DIRECCION,
      CONTACTO,
      FILTRO_PRECIO,
      LOGO,
      MONTO_ENVIO_GRATIS,
      PAGA_DEPOSITO,
      PAGAPEDIDO,
      PAGA_VISA,
      PAGINA,
      TELEFONO,
      TEXTO_COMPRA,
      VALIDO,
      BANNER,
      MONEDA,
      TIPO_CAMBIO,
    }))(response);
    localStorage.setItem("config", JSON.stringify(newData));
  } else {
    console.log("OCURRIO UN ERROR INESPERADO");
  }
};

export const getMenu = async () => {
  let response = await api.get2(config.apiSrvMenu, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
  });
  if (response.VALIDO === 1) {
    return;
  } else {
    console.log("OCURRIO UN ERROR INESPERADO");
  }
};

export const designConfig = {};

import * as api from "../api";
import * as types from "../constants/action-types";
import cart from "../reducers/cart";
import API from "../utils/api";
import Config from "../utils/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Swal from "sweetalert2";

/**
 * Receive all Products
 * @param { Array } products
 */
export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});
export const logins = (datosLogin) => ({
  type: types.LOGIN,
  datosLogin,
});
export const envio_pago = (datosEnvio) => ({
  type: types.ENVIO_PAGO,
  datosEnvio,
});
export const datos_personales = (datos) => ({
  type: types.DATOS_PERSONALES,
  datos,
});
export const fomulario_dinamico = (datos) => ({
  type: types.FORM,
  datos,
});
export const metodoPago = (datos) => ({
  type: types.METODO_PAGO,
  datos,
});
export const banner = (datos) => ({
  type: types.BANNER,
  datos,
});
export const login = (params) => (dispatch) => {
  API.get2(Config.apiSrvLoginCreate, { ...params }).then((cart) => {
    dispatch(logins(cart));
    return cart;
  });
};
/**
 * Receive all Categories
 * @param { Array } categories
 */
export const receiveCategories = (categories) => ({
  type: types.RECEIVE_CATEGORIES,
  categories,
});

/**
 * Receive all Shops
 * @param { Array } shops
 */
export const receiveShops = (shops) => ({
  type: types.RECEIVE_SHOPS,
  shops,
});

/**
 * Receive all Cart
 * @param { Array } cart
 */
export const receiveCart = (cart) => ({
  type: types.RECEIVE_CART,
  cart,
});

/**
 * Refresh Store
 * @param {*} current
 */
export const refreshUnSafe = (current) => ({
  type: types.REFRESH_STORE,
  current,
});

/**
 * Dispatch Refresh Store
 * @param { Number } demo
 */
export const refreshStore = (current) => (dispatch) => {
  dispatch(refreshUnSafe(current));
};

/**
 * Get all Products
 */
export const getAllProducts = (params) => (dispatch) => {
  /* api.getProducts().then( products => {
        dispatch( receiveProducts( products ) );
        return products;
    } ) */
  API.get(Config.apiSrvProductTop, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
    ...params,
  }).then((products) => {
    dispatch(receiveProducts(products.LISTA));
    return products;
  });
};

/**
 * Get all Categories
 */
export const getAllCategories = (params) => (dispatch) => {
  API.get(Config.apiSrvMenu, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
    ...params,
  }).then((categories) => {
    dispatch(receiveCategories(categories.LISTA));
    return categories;
  });
};

/**
 * Get all Categories
 */
export const getAllShops = () => (dispatch) => {
  API.get(Config.apiSrvListComercios, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
  }).then((shops) => {
    dispatch(receiveShops(shops.LISTA));
    return shops;
  });
};

/**
 * Get all Cart
 */
export const getAllCart = (params) => (dispatch) => {
  const temp = JSON.parse(localStorage.getItem("login_temp"));
  const verficarDataPago = JSON.parse(localStorage.getItem("paymentData"));

  API.get2(Config.apiSrvListCart, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
    ...params,
  }).then((cart) => {
    dispatch(receiveCart(cart));
    if (cart.VALIDO == 0) {
      if (temp == null && verficarDataPago == null) {
        localStorage.removeItem("usuario");
        // window.location.href = "/pages/login";
        window.location.href = "/";
      } else {
        localStorage.removeItem("login_temp");
        window.location.reload();
      }
    }
    return cart;
  });
};

/**
 * Get a Product
 * @param { String } productId
 */
export const getProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

/**
 * Show QuickView Modal
 * @param { Object } product
 */
// export const showQuickView = ( product ) => dispatch => {
//     dispatch({ type: types.SHOW_QUICKVIEW, product });
// }
export const showQuickView = (product) => ({
  type: types.SHOW_QUICKVIEW,
  product,
});

export const showConditions = (product) => ({
  type: types.QUICK_VIEW_CONDITIONS,
  product,
});

export const showDenegateBuy = (product) => ({
  type: types.QUICK_VIEW_DENEGATE,
  product,
});

export const showInfoUserVerificated = (product) => ({
  type: types.QUICK_VIEW_USER_VERIFICATION,
  product,
});

/**
 * Hide QuickView Modal
 */
export const hideQuickView = () => (dispatch) => {
  dispatch({ type: types.HIDE_QUICKVIEW });
};

export const hideQuickViewConditions = () => (dispatch) => {
  dispatch({ type: types.HIDE_QUICK_VIEW_CONDITIONS });
};

export const hideQuickViewDenegateBuy = () => (dispatch) => {
  dispatch({ type: types.HIDE_QUICK_VIEW_DENEGATE });
};

export const hideQuickViewUserInfoVerificated = () => (dispatch) => {
  dispatch({ type: types.HIDE_QUICK_VIEW_USER_VERIFICATION });
};

/****************** Cart Action *****************/

/**
 * Add to Cart
 * @param { Object } product
 * @param { Number } cant
 * @param { string } tokenApi
 * @param { string } identificador
 */

const handleToastClick = () => {
  // Función a ejecutar al hacer clic en el contenido del toast
  window.location.href = "/categories/verificacion";
  // Aquí puedes realizar la lógica adicional que necesites
};

export const AddCartApi = (
  product,
  cant,
  tokenApi,
  identificador,
  id
) => async (dispatch) => {
  let cart = [];

  try {
    //obtener info user
    let user = {};
    user = await localStorage.getItem("usuario");
    user = JSON.parse(user);

    let token = await localStorage.getItem("tokenIdentidad");
    //obtenr carrito actual
    let carr = localStorage.getItem("porto-cartlist");
    if (carr && carr !== "" && carr !== null && carr !== undefined) {
      carr = JSON.parse(carr);

      if (carr && carr.cart1) {
        carr = JSON.parse(carr.cart1);

        if (carr && carr.CARRITO && carr.CARRITO.lineas) {
          cart = carr.CARRITO.lineas;
        }
      }
    }

    let item = cart.find((item) => {
      return item.CODIGO === product.CODIGO_SKU;
    });
    let cantidad = parseInt(cant);

    if (
      (item != undefined &&
        product.CODIGO_SKU == process.env.REACT_APP_SDK_IDENTIDAD_DIGITAL) ||
      (item != undefined && product.IDENTIDAD_DIGITAL === 1)
    ) {
      toast.error(
        "Este producto ya está en el carrito, solo puedes adquirir uno por compra",
        {
          autoClose: 1500,
        }
      );
      return;
    }

    // al momento de agregar un producot sin identidad digital, busca si ya tiene un producto con identidad digital en el carrito y si es asi, vacia el carrito
    const itemsIdentidad = cart.find((item) => {
      return item.PRODUCTO_FINANCIERO === 1;
    });

    if (itemsIdentidad != null && product.IDENTIDAD_DIGITAL === 0) {
      toast.error(
        "Cuenta con un producto financiero en el carrito, por favor elimine el producto para continuar.",
        {
          autoClose: 1500,
        }
      );
      return;
    }
    if (item && id) {
      cantidad = parseInt(item.CANTIDAD) + cantidad;
    }

    if (isNaN(cantidad)) {
      toast.error("Debes ingesar una cantidad valida", {
        autoClose: 1500,
      });
      return;
    }

    if (cantidad < 1) {
      toast.error("La cantidad debe ser mayor a 0", {
        autoClose: 1500,
      });
      return;
    }
    if (product.EXISTENCIA < cantidad) {
      toast.error("La cantidad solicitada no está disponible", {
        autoClose: 1500,
      });
      return;
    }

    if (product.IDENTIDAD_DIGITAL === 1) {
      if (!user || user === "") {
        // para este producto se requiere identidad digital y no hay usuario con sesion activa por lo que se le pregunta si desea iniciar sesion
        Swal.fire({
          title: "Necesitas iniciar sesión",
          icon: "warning",
          iconColor: "#0C518C",
          showCancelButton: true,
          confirmButtonColor: "#0C518C",
          cancelButtonColor: "#d33",
          cancelButtonText: "Luego",
          confirmButtonText: "Iniciar sesión",
          html:
            '<div style="font-size: 14px;">Necesitas iniciar sesión para agregar al carrito este producto. <br> ¿Deseas iniciar sesión?</div>',
          customClass: {
            cancelButton: "swal-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirecciona a la ruta deseada
            window.location.href = "/pages/login";
          }
        });

        return;
      }
      if (
        product.CODIGO_SKU == process.env.REACT_APP_SDK_IDENTIDAD_DIGITAL &&
        user.DATA.IDENTIDAD_DIGITAL_REGISTRADA
      ) {
        toast.success("El usuario ya cuenta con identidad digital", {
          autoClose: 1500,
        });
        return;
      }
      if (
        product.CODIGO_SKU == process.env.REACT_APP_SDK_IDENTIDAD_DIGITAL &&
        (user.DATA.CODIGO_IDENTIDAD || token) &&
        !user.DATA.IDENTIDAD_DIGITAL_REGISTRADA
      ) {
        toast.success("Ya compraste identidad digital", {
          autoClose: 1500,
        });
        setTimeout(() => {
          window.location.href = "/pages/checkout/buys";
        }, 5000);
        return;
      }
      if (
        user.DATA.IDENTIDAD_FORZADA &&
        user.DATA.IDENTIDAD_DIGITAL_REGISTRADA
      ) {
        (async () => {
          const result = await Swal.fire({
            title: "Actualizar información",
            icon: "warning",
            iconColor: "#0C518C",
            showCancelButton: true,
            confirmButtonColor: "#0C518C",
            cancelButtonText: "Luego",
            confirmButtonText: "Actualizar Información",
            input: "checkbox",
            inputValue: 0,
            inputPlaceholder: "Aceptar",
            confirmButtonText: "Continuar &rarr;",
            html:
              '<div style="font-size: 14px;">Tu información tiene que ser actualizada ya que no coincide con la de identidad digital. <br> ¿Deseas actualizar la información automáticamente?</div>',
            customClass: {
              cancelButton: "swal-button",
            },
            inputValidator: (result) => {
              return !result && "Debes aceptar para continuar";
            },
          });

          if (result.isConfirmed) {
            const respuesta = await API.get2(Config.identidadForzada, {
              token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
              token_api: user.DATA.TOKEN_API,
            });
            user.DATA.IDENTIDAD_FORZADA = false;
            const modifiedndstrigifiedForStorage = JSON.stringify(user);
            localStorage.setItem("usuario", modifiedndstrigifiedForStorage);
            toast.success("Información actualizada con éxito", {
              autoClose: 1500,
            });
          }
        })();

        return;
      }

      if (product.EXISTENCIA < cantidad) {
        toast.error("La cantidad solicitada no está disponible", {
          autoClose: 1500,
        });
        return;
      }

      if (!user.DATA.IDENTIDAD_DIGITAL_REGISTRADA) {
        dispatch(showConditions(product));
        return;
      }
      agregarCotizacion(product);
      return;
    }

    if (!user || user === "") {
      // sino se encuentra usuario activo iremos a traer el temporal
      // Obtener y asignar los datos desde localStorage
      let userData = localStorage.getItem("login_temp");
      userData = JSON.parse(userData);

      const newData = {
        DATA: {
          ...userData,
        },
      };
      user = newData;
    }

    API.get2(Config.apiSrvAgregar, {
      token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
      token_api: user.DATA.TOKEN_API,
      tipo: "PRODUCTO",
      codigo: product.CODIGO == undefined ? product.CODIGO_SKU : product.CODIGO,
      cantidad: cantidad,
      valor:
        product.PRECIO == undefined ? product.VALOR_UNITARIO : product.PRECIO,
      identificador: user.DATA.IDENTIFICADOR_COMPRA,
      codigo_solicitud: typeof tokenApi === "string" ? 0 : tokenApi,
    })
      .then((cart) => {
        return cart;
      })
      .then(function(res) {
        if (res && res.VALIDO === 1) {
          toast.success("Se agregó al carrito de compras", {
            autoClose: 5000,
          });
          dispatch(
            getAllCart({
              token_api: user.DATA.TOKEN_API,
              identificador: user.DATA.IDENTIFICADOR_COMPRA,
            })
          );
          return res.VALIDO;
        } else if (token) {
          toast.success("Ya compraste identidad digital", {
            autoClose: 1500,
          });
          setTimeout(() => {
            window.location.href = "/pages/checkout/buys";
          }, 5000);
        } else {
          console.log("Error");
        }
      });

    //validar si el producto es de identidad digital el codigo CODIGO_SKU: "2A131359000000P0002"
    if (
      product.CODIGO_SKU == process.env.REACT_APP_SDK_IDENTIDAD_DIGITAL &&
      !token
    ) {
      if (token) {
        toast.success("Ya compraste identidad digital", {
          autoClose: 1500,
        });
        setTimeout(() => {
          window.location.href = "/pages/checkout/buys";
        }, 5000);
      }
      setTimeout(() => {
        window.location.href = "/pages/cart";
      }, 5000);
    }
  } catch (err) {
    console.log(err);
  }

  /* API.get(Config.apiSrvListComercios, {token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS}).then( addCart => {
        dispatch( receiveAddCart( addCart.LISTA ) );
        //console.log(products.LISTA[0], 'RESPEUSTA DEL MERTODO DE PRODUCTOS HOLA=====')
        return addCart;
    }) */
};

export const AddCartApi3 = (
  product,
  cant = 1,
  tokenApi,
  identificador
) => async (dispatch) => {
  try {
    let user = await localStorage.getItem("usuario");
    user = JSON.parse(user);

    if (!user || user === "") {
      // Mostrar mensaje de error que necesita iniciar sesión
      toast.error("Necesitas iniciar sesión para agregar al carrito", {
        autoClose: 1500,
      });
      return;
    }

    API.get2(Config.apiSrvAgregar, {
      token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
      token_api: user.DATA.TOKEN_API,
      tipo: product.NIVEL_UNO.NOMBRE,
      codigo: product.CODIGO == undefined ? product.CODIGO_SKU : product.CODIGO,
      cantidad: cant,
      valor:
        product.PRECIO == undefined ? product.VALOR_UNITARIO : product.PRECIO,
      identificador: identificador,
      codigo_solicitud: 18,
    })
      .then((cart) => {
        return cart;
      })
      .then(function(res) {
        if (res.VALIDO === 1) {
          //  toast.success( "Se agregó al carrito de compras" );
          dispatch(
            getAllCart({
              token_api: user.DATA.TOKEN_API,
              identificador: identificador,
            })
          );
        } else {
          console.log("EL API RESPONDIO ERROR #########");
        }
        return res.VALIDO;
      });
  } catch (err) {
    console.log(err);
  }
};

const agregarCotizacion = async (e) => {
  let cotizacion = localStorage.getItem("cotizacion");
  let parsedCotizacion = JSON.parse(cotizacion);

  //   {
  //     "IDENTIFICADOR_LINEA": 3184,
  //     "IMAGEN": "/uploads/producto_177/thumbnail/62a7542edc58a410dc49dac8_contratar-seguro-de-vida_(1)jpg.jpg",
  //     "CODIGO": "2A1313592B18000P0003",
  //     "TIPO": "PRODUCTO",
  //     "NOMBRE": "Seguro de Vida",
  //     "VALOR_UNITARIO": 4990,
  //     "CANTIDAD": 1,
  //     "EXISTENCIA_ACTUAL": 25,
  //     "VALOR_TOTAL": 4990
  // }
  // Verificar si hay una cotización existente en el localStorage
  if (!parsedCotizacion) {
    // Si no hay cotización existente, crear un nuevo objeto de cotización
    parsedCotizacion = {
      cart: [],
      cart1: {
        VALIDO: 1,
        DETALLE: "DETALLE DE CARRITO",
        PRODUCTOS: 0,
        CARRITO: {
          CANTIDAD_PRODUCTO: 0,
          TOTAL_PRODUCTO: 0,
          TOTAL_DESCUENTO: 0,
          TOTAL_CARGO: 0,
          GRAN_TOTAL: 0,
          lineas: [],
        },
      },
      modalProduct: "",
      showModal: false,
    };
  }
  let tempProducto = {
    IMAGEN: e.IMAGENES[0].RUTA,
    CODIGO: e.CODIGO_SKU,
    TIPO: e.NIVEL_UNO.NOMBRE,
    NOMBRE: e.NOMBRE,
    VALOR_UNITARIO: e.PRECIO,
    CANTIDAD: 1,
    EXISTENCIA_ACTUAL: e.EXISTENCIA,
    VALOR_TOTAL: e.PRECIO,
    IDENTIDAD_DIGITAL: 1,
  };

  const buscarProducto = parsedCotizacion.cart1.CARRITO.lineas.find(
    (item) => item.CODIGO === tempProducto.CODIGO
  );
  if (buscarProducto != undefined) {
    toast.error("Este tipo de producto solo se puede agregar una vez", {
      autoClose: 1500,
    });
    return;
  }
  //actualizmos array unicamnete mostrar el ultimo producto agregado
  parsedCotizacion.cart1.CARRITO.lineas[0] = tempProducto;

  // Actualizar el total de la cotización suma de lo que ya estaba en la cotización + el nuevo producto
  parsedCotizacion.cart1.CARRITO.GRAN_TOTAL = tempProducto.VALOR_TOTAL;

  // Convertir el objeto de cotización actualizado a JSON
  let updatedCotizacion = JSON.stringify(parsedCotizacion);

  // Guardar la cotización actualizada en el localStorage
  localStorage.setItem("cotizacion", updatedCotizacion);

  //mostrar alerta de producto agregado
  toast.success("Se ha actualizado la cotización", {
    autoClose: 1500,
  });

  setTimeout(() => {
    window.location.href = "/pages/cotizacion";
  }, 4000);
};

export const addToCart = (product, qty = 1) => (dispatch) => {
  dispatch({ type: types.SHOW_CART_MODAL, product });
  dispatch({ type: types.ADD_TO_CART, product, qty });
};

/**
 * Add to Cart in modal
 * @param { Object } product
 * @param { Number } qty
 */
export const quickAddToCart = (product, qty = 1) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch({ type: types.ADD_TO_CART, product, qty });
};

/**
 * Add item in Wishlist to Cart
 * @param { Object } product
 * @param { Number } qty
 */
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch({ type: types.ADD_TO_CART, product, qty });
  dispatch({ type: types.REMOVE_FROM_WISHLIST, product });
};

/**
 * Remove item from Cart
 * @param { Object } product
 */
export const removeFromCart = (product, estado) => (dispatch) => {
  let user = localStorage.getItem("usuario");
  if (!user || user === "") {
    // Mostrar mensaje de error que necesita iniciar sesión
    let userData = localStorage.getItem("login_temp");
    userData = JSON.parse(userData);

    const newData = {
      DATA: {
        ...userData,
      },
    };
    user = newData;
  } else {
    user = JSON.parse(user);
  }

  API.get2(Config.apiSrvElminarCarrito, {
    token: process.env.REACT_APP_CODIGO_EMPRESAS_CMS,
    token_api: user.DATA.TOKEN_API,
    identificador_linea: product.IDENTIFICADOR_LINEA,
  })
    .then((res) => {
      return res;
    })
    .then((res) => {
      if (res.VALIDO === 1) {
        dispatch(
          getAllCart({
            token_api: user.DATA.TOKEN_API,
            identificador: user.DATA.IDENTIFICADOR_COMPRA,
          })
        );
      } else {
        console.log(res);
      }
      return res.VALIDO;
    });
  dispatch({ type: types.REMOVE_FROM_CART, product });
};

/**
 * Clear Cart
 * @param { Object } product
 */
export const clearCart = (product) => (dispatch) => {
  toast.error("Cart Cleared");
  dispatch({ type: types.CLEAR_CART, product });
};

/**
 * Increment quantity of item
 * @param { Object } product
 * @param { Number } qty
 */
export const incrementQty = (product) => (dispatch) => {
  dispatch({ type: types.ADD_TO_CART, product, qty: 1 });
};

/**
 * Decrement quantity of item
 * @param { String } product
 */
export const decrementQty = (product) => (dispatch) => {
  dispatch({ type: types.DECREMENT_QTY, product });
};

/********************* Wishlist Action *********************/

/**
 * Add item to Wishlist
 * @param { Object } product
 */
export const addToWishList = (product) => (dispatch) => {
  toast.success("Artículo añadido a la lista de deseos", {
    autoClose: 1500,
  });
  dispatch({ type: types.ADD_TO_WISHLIST, product });
};

/**
 * Remove item from Wishlist
 * @param { String } productId
 */
export const removeFromWishlist = (product) => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({ type: types.REMOVE_FROM_WISHLIST, product });
};

/**
 * Remove item from Cart and Add to Whishlist
 * @param { String } product
 */
export const moveFromCartToWishlist = (product) => (dispatch) => {
  toast.success("Item Moved to Wishlist");
  dispatch({ type: types.REMOVE_FROM_CART, product });
  dispatch({ type: types.ADD_TO_WISHLIST, product });
};

/**
 * Remove item from Wishlist and Add to Cart
 * @param { String } product
 */
export const moveFromWishlistToCart = (product) => (dispatch) => {
  toast.success("Item Moved to Cart");
  dispatch({ type: types.REMOVE_FROM_WISHLIST, product });
  dispatch({ type: types.ADD_TO_CART, product, qty: 1 });
};

/********************* Compare Action *********************/

/**
 * Add item to Compare List
 * @param { Object } product
 */
export const addToCompare = (productId) => (dispatch) => {
  dispatch({ type: types.ADD_TO_COMPARE, productId });
};

/**
 * Remove item form Compare List
 * @param { String } productId
 */
export const removeFromCompare = (productId) => (dispatch) => {
  dispatch({ type: types.REMOVE_FROM_COMPARE, productId });
};

/**
 * Clear all compare products
 *
 */
export const clearAllCompare = () => (dispatch) => {
  dispatch({ type: types.REMOVE_ALL_COMPARE });
};

/********************* Filter Action *********************/

/**
 * Filter by Brand
 * @param { String }  brand
 */
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});

/**
 * Filter by Color
 * @param { String } color
 */
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});

/**
 * Filter by Price
 * @param { Number } value
 */
export const filterPrice = (price) => ({
  type: types.FILTER_PRICE,
  price,
});

/**
 * Sort by
 * @param { String } sortBy
 */
export const filterSort = (sortBy) => ({
  type: types.SORT_BY,
  sortBy,
});

/**
 * Filter by Size
 * @param { Number } value
 */
export const filterSize = (size) => ({
  type: types.FILTER_SIZE,
  size,
});

/**
 * Filter by categories
 * @param { Number } value
 */
export const filterCategories = (category) => {
  return {
    type: types.FILTER_CATEGORIES,
    category,
  };
};

/********************* Newsletter Modal Action *********************/
export const hideNewsletter = () => (dispatch) => {
  dispatch({ type: types.HIDE_NEWSLETTER });
};

/**
 * Clean All Filter
 *
 */
export const cleanFilter = () => ({
  type: types.FILTER_CLEAN_FILTERS,
});
